<template>
  <div class="modal-container">
    <div class="modal-header">
      <h5 class="title">
        아이디 정보를 확인해주세요.
      </h5>
    </div>
    <div class="modal-center">
      <p>아이디는 앞 3자리 외 '*'로 표시됩니다.</p>
      <strong>{{ email + email2 }}</strong>
    </div>
    <div class="modal-footer">
      <button class="btn-confirm" @click="btnConfirm">로그인 하기</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "FindIdDialog",
  props:{
    email : String,
    email2 : String,
  },
  methods: {
    btnConfirm() {
      const self = this;
      self.$emit('callBackModal', 0)
    },
  }
}
</script>

<style scoped>

.modal-container {
  position: fixed;
  width: 580px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
  background: #FFFFFF;
  border-radius: 8px;
}

.modal-header {
  background: #FFFFFF;
  border-radius: 8px 8px 0 0;
  height: 76px;
  padding: 24px 20px;
}

.title {
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0.0015em;
  color: rgba(24, 24, 25, 0.9);
  font-size: 16px;
  margin: 0;
}

.modal-center {
  padding: 30px 60px;
  background: #F8F8FA;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.0025em;
}

.modal-center strong {
  color: #4C9AF5;
}

.modal-footer {
  background: #FFFFFF;
  border-radius: 0 0 8px 8px;
}

.btn-confirm {
  width: 109px;
  height: 40px;
  background: #4C9AF5;
  border-radius: 4px;
  font-weight: 500;
  line-height: 36px;
  letter-spacing: 0.0125em;
  text-transform: uppercase;
  color: #FFFFFF;
  border: 0;
}


</style>
